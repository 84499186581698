import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SearchReceipt",
    component: () => import("../views/SearchReceiptV2.vue"),
    children:[{
      path: "",
      name: "SearchReceiptForm",
      component: () => import("../components/SearchReceiptForm.vue"),
    },{
      path: "results",
      name: "ReceiptDetails",
      component: () => import("../components/ReceiptDetails.vue"),
    },
    {
      path: "adv-search",
      name: "AdvSearch",
      component: () => import("../components/AdvSearchForm.vue"),
    }, 
    {
      path: "receipt-not-found",
      name: "ReceiptNotFound",
      component: () => import("../components/ReceiptNotFound.vue"),
    },
    {
      path: "multiple-receipts-found",
      name: "MultipleReceiptsFound",
      component: () => import("../components/MultipleReceiptsFound.vue"),
    }
  ]
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});
export default router;
